import React from "react";
import slugify from "./slugify";
import useSheet from "react-jss";
import { bootstrap } from "toetag";

export const faqLinkStyles = {
	color: "#59a3e8",
	"&:hover, &:focus": {
		color: "#4a90e2",
		textDecoration: "none !important"
	}
};

const ruleWidth = 4;

const styles = {
	container: {
		marginLeft: `-${bootstrap.navbarPaddingHorizontal} !important`,
		borderLeft: "1px dotted #4a90e2",
		// workaround for ugly antialiasing on dotted borders in chrome
		borderTopLeftRadius: 1,
		borderBottomLeftRadius: "2px 1px"
	},
	tocItem: {
		listStyle: "none !important",
		padding: 0,
		marginLeft: -1,
		fontSize: 16,
		"& > a": {
			display: "block",
			padding: [
				bootstrap.paddingBaseVertical,
				bootstrap.navbarPaddingHorizontal
			],
			...faqLinkStyles
		},
		"& $tocItem": {
			fontSize: 12,
			"& > a": {
				padding: [3, bootstrap.navbarPaddingHorizontal],
				whiteSpace: "nowrap",
				overflow: "hidden",
				textOverflow: "ellipsis"
			}
		}
	},
	tocItemActive: {
		borderLeftColor: "#4a90e2",
		borderLeftStyle: "solid",
		borderLeftWidth: ruleWidth,
		composes: "$tocItem",
		backgroundColor: bootstrap.navbarDefaultBg,
		"& > a": {
			backgroundColor: bootstrap.navLinkHoverBg,
			paddingLeft:
				parseInt(bootstrap.navbarPaddingHorizontal.replace("px", "")) -
				ruleWidth
		}
	}
};

const TableOfContents = ({ faqs, classes, activeItemHref }) => {
	if (!faqs || !faqs.length) return null;
	return (
		<ul className={classes.container}>
			{faqs.map(({ value: heading }) => {
				const slugged = slugify(heading);
				const isActive = activeItemHref === slugged;
				return (
					<li
						key={slugged}
						className={isActive ? classes.tocItemActive : classes.tocItem}
					>
						<a href={`#${slugged}`}>{heading}</a>
					</li>
				);
			})}
		</ul>
	);
};

export default useSheet(styles)(TableOfContents);
