import React, { Component } from "react";
import { Panel, Clearfix, Grid, Row, Col } from "react-bootstrap";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import styles from "../styles";

import TableOfContents from "./table-of-contents";

import { AutoAffix } from "react-overlays";

const topStyle = {
	paddingLeft: 15,
	marginLeft: -15,
	overflowX: "visible",
	overflowY: "auto"
};

const affixStyle = {
	paddingLeft: 15,
	marginLeft: -15,
	overflowX: "visible",
	overflowY: "auto"
};

const bottomStyle = {
	paddingLeft: 15,
	marginLeft: -15,
	overflowX: "visible",
	overflowY: "auto"
};

class FaqPage extends Component {
	state = {
		activeNavItemHref: null
	};

	componentDidMount() {
		this._afterSections = {};
		this._hashListener = window.addEventListener(
			"hashchange",
			this.getActiveFromHash
		);
	}

	componentWillUnmount() {
		window.removeEventListener("hashchange", this._hashListener);
	}

	getActiveFromHash = () => {
		const { hash } = window.location;
		if (hash) {
			this.setState({
				activeNavItemHref: hash.replace("#", "")
			});
		}
	};

	handleEnter = () => {
		return;
	};

	handleLeave = () => {
		return;
	};

	handlePositionChange = ({ href, currentPosition }) => {
		if (currentPosition === "inside") {
			this.setState({ activeNavItemHref: href });
		}
	};

	render() {
		const {
			classes,
			data: {
				contentfulPage: {
					title,
					content: {
						childMarkdownRemark: { html: content, headings }
					}
				}
			}
		} = this.props;

		// Yay for last-minute demands from on high
		const titleWithJunkRemoved = title.toLowerCase().endsWith("-hidden")
			? title.substr(0, title.length - 7)
			: title;

		return (
			<Grid>
				<Row>
					<Clearfix>
						<Panel>
							<Helmet>
								<title>{titleWithJunkRemoved}</title>
							</Helmet>

							<div
								className={classes.faqContainer}
								ref={el => (this._faqContainer = el)}
							>
								<h1 className={classes.pageTitle}>{titleWithJunkRemoved}</h1>
								{headings && headings.length ? (
									<Row>
										<Col md={4} lg={3} className="hidden-sm hidden-xs">
											<AutoAffix
												autoWidth
												viewportOffsetTop={0}
												viewportOffsetBottom={0}
												{...{ topStyle, affixStyle, bottomStyle }}
												container={this._faqContainer}
											>
												<div>
													<TableOfContents
														activeItemHref={this.state.activeNavItemHref}
														faqs={headings}
													/>
												</div>
											</AutoAffix>
										</Col>
										<Col md={8} lg={9}>
											<div
												className={classes.faqContent}
												dangerouslySetInnerHTML={{ __html: content }}
											/>
										</Col>
									</Row>
								) : null}
							</div>
						</Panel>
					</Clearfix>
				</Row>
			</Grid>
		);
	}
}

export default styles(FaqPage);

export const pageQuery = graphql`
	query FaqBySlug($slug: String!) {
		contentfulPage(slug: { eq: $slug }, pageType: { eq: "FAQ" }) {
			slug
			title
			content {
				childMarkdownRemark {
					html
					headings {
						value
						depth
					}
				}
			}
		}
	}
`;
