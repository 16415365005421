import useSheet from "react-jss";

export default useSheet({
	container: {
		composes: ["home-section__container"],
		"& ul": {
			marginLeft: "1.2em"
		},
		"& ul li": {
			listStyle: "disc"
		}
	},
	faqContainer: {
		composes: "$container",
		padding: 0,
		color: "#435567"
	},
	faqContent: {
		"& h1, & h2, & h3, & h4, & h5, & h6": {
			fontSize: "1em",
			fontWeight: "bold"
		},
		"& section": {
			fontSize: 18,
			padding: [15, 0],
			borderTop: "1px dotted #7689a0",
			"&:first-child": {
				borderTop: "none"
			}
		}
	},
	pageTitle: {
		fontSize: 33,
		marginTop: 0,
		fontWeight: 300
	},
	tocHeader: {
		fontSize: 19,
		fontWeight: "400 !important"
	}
});
